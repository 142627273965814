import { useMediaQuery } from '@mui/material';
import cn from 'classnames';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userGroupSelector } from '../../../redux/user/selectors';
import { userCoursesSelector } from '../../../redux/userCourses/selectors';
import { getUserCoursesThunk } from '../../../redux/userCourses/thunks';
import {
  openChatModal,
  setInterlocutor,
} from '../../../redux/view/chat/reducer';
import { chatUnreadMessagesSelector } from '../../../redux/view/chat/selectors';
import { ChatInterlocutor } from '../../../redux/view/chat/types';
import { Navigation } from '../../../shared/components/Navigation';
import {
  MEDIA_QUERY_DESKTOP,
  MEDIA_QUERY_MOBILE,
  MEDIA_QUERY_TABLET,
} from '../../../shared/theme/mediaQuery';
import { ChatPreview } from './ChatPreview';
import { getChatPreviewLastMessage, getLastMessageDate } from './utils';
import styles from './styles.module.scss';

export const ChatPage = () => {
  const dispatch = useAppDispatch();

  const courses = useAppSelector(userCoursesSelector);
  const userGroup = useAppSelector(userGroupSelector);
  const lastUnreadMessages = useAppSelector(chatUnreadMessagesSelector);

  const matchesDesktop = useMediaQuery(MEDIA_QUERY_DESKTOP);
  const matchesTablet = useMediaQuery(MEDIA_QUERY_TABLET);
  const matchesMobile = useMediaQuery(MEDIA_QUERY_MOBILE);

  const PAGE_CONTAINER_STYLES = cn(
    { [styles['page-container']]: matchesDesktop },
    {
      [styles['page-container-tablet']]: matchesTablet && !matchesDesktop,
    },
    {
      [styles['page-container-mobile']]: matchesMobile && !matchesTablet,
    }
  );

  useEffect(() => {
    if (!courses?.results?.length && userGroup) {
      dispatch(getUserCoursesThunk(userGroup));
    }
  }, [userGroup]);

  const handleChatClick = (interlocutor: ChatInterlocutor) => {
    dispatch(setInterlocutor(interlocutor));
    dispatch(openChatModal());
  };

  return (
    <>
      <Navigation />
      <div className={PAGE_CONTAINER_STYLES}>
        {userGroup === 'student' && courses?.results?.length && (
          <>
            <h1>Мои наставники</h1>
            <div className={styles.chats}>
              {courses.results.map(course => {
                const lastMessage = getChatPreviewLastMessage(
                  course,
                  lastUnreadMessages
                );

                return (
                  <ChatPreview
                    key={course.id}
                    fullName={`${course?.mentor?.['first_name']} ${course?.mentor?.['last_name']}`}
                    courseName={course?.name}
                    lastMessageText={lastMessage?.text}
                    lastMessageDate={getLastMessageDate(
                      lastMessage?.created_at
                    )}
                    onClick={() =>
                      handleChatClick({
                        id: course?.mentor?.id,
                        name: course?.mentor?.['first_name'] ?? '',
                        surname: course?.mentor?.['last_name'] ?? '',
                        courseName: course?.name ?? '',
                        courseId: course?.id,
                        /* Студента определяем выше */
                        isStudent: false,
                      })
                    }
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};
