import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material';
import { store } from './redux/store';
import { auth as user } from './api/Auth';
import { AuthPage } from './pages/AuthPage';
import { MainPage } from './pages/MainPage';
import { Course } from './pages/Course';
import { AllCourses } from './pages/AllCourses';
import { ScrollToTop } from './shared/components/ScrollToTop/ScrollToTop';
import { ChatProvider } from './shared/components/Chat';
import { CHATS_URL_PART, COURSES_URL_PART } from './shared/constants/paths';
import { ChatPage } from './pages/MainPage/ChatPage';
import Modules from './pages/Modules';
import Lessons from './pages/Lessons';
import LessonsChange from './pages/LessonsChange';
import Profile from './pages/Profile';

export const App = () => {
  const loggedIn = user.isAuthenticated();

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ChatProvider observing={loggedIn}>
          <Router>
            <ScrollToTop />
            <Routes>
              {!loggedIn && <Route path='login' element={<AuthPage />} />}
              {loggedIn && (
                <>
                  <Route path='/' element={<MainPage />} />
                  <Route
                    path={`${COURSES_URL_PART}/:courseName`}
                    element={<Course />}
                  >
                    <Route path='module-:moduleId' element={<Outlet />}>
                      <Route path='lesson-:lessonId' element={<Outlet />} />
                    </Route>
                  </Route>
                  <Route
                    path={`${COURSES_URL_PART}/all`}
                    element={<AllCourses />}
                  />
                  <Route
                    path={`${COURSES_URL_PART}/all/modules`}
                    element={<Modules />}
                  />
                  <Route
                    path={`${COURSES_URL_PART}/all/modules/lessons`}
                    element={<Lessons />}
                  />
                  <Route
                    path={`${COURSES_URL_PART}/all/modules/lessons/change`}
                    element={<LessonsChange />}
                  />
                  <Route path={CHATS_URL_PART} element={<ChatPage />} />
                  <Route path={'profile'} element={<Profile />} />
                </>
              )}
              <Route
                path='*'
                element={<Navigate to={loggedIn ? '/' : '/login'} />}
              />
            </Routes>
          </Router>
        </ChatProvider>
      </StyledEngineProvider>
    </Provider>
  );
};
