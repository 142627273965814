import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { DragAndDropCursor } from './DragAndDropCursor';
import { TMousePos } from '../types';
import { TDataChange } from '../../../../types';
import s from './MediaPhotoArea.module.scss';
import { api } from '../../../../../../../api';

const MAX_FILE_SIZE = 11004936;

type TProps = {
  id: number;
  handleDelete: () => void;
  setData: React.Dispatch<React.SetStateAction<TDataChange>>;
};

export const MediaPhotoDevice = ({ id: realId, setData }: TProps) => {
  const search = useLocation().search;
  const idLesson = new URLSearchParams(search).get('lesson-id');

  const [isDragAndDrop, setIsDragAndDrop] = useState(false);
  const [filesCount, setFilesCount] = useState(1);
  const [mousePos, setMousePos] = useState<TMousePos>(null);

  const inputRef = useRef(null);

  const uploadFilesHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = Object.values(e.target.files);
    const filesSize = files.reduce((sum, file) => (sum += file.size), 0);
    setFilesCount(files.length);

    if (filesSize > MAX_FILE_SIZE) {
      alert('Файл слишком большой');
    } else {
      try {
        const res = await api.lesson.lessonAttachmentCreate(idLesson, {
          filename: files[0].name,
          content_type: 'photo',
        });
        const {
          data: { id },
        } = res;

        const attachment = await api.lesson.lessonAttachmentDataCreate(
          String(id),
          idLesson,
          files,
        );
        const { url } = attachment;

        setData(state => ({
          ...state,
          [realId]: {
            ...state[realId],
            value: url,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragAndDrop(true);
  };

  const handleDragEnd = e => {
    e.preventDefault();
    setIsDragAndDrop(false);
  };

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragAndDrop(true);
    const { clientX, clientY } = e;
    setMousePos({ x: clientX, y: clientY });
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragAndDrop(false);

    const files = Object.values(e.dataTransfer.files);
    const filesSize = files.reduce((sum, file) => (sum += file.size), 0);
    setFilesCount(files.length);

    if (filesSize > MAX_FILE_SIZE) {
    } else {
      try {
        const res = await api.lesson.lessonAttachmentCreate(idLesson, {
          filename: files[0].name,
          content_type: 'photo',
        });
        const {
          data: { id },
        } = res;

        // await api.lesson.lessonAttachmentDataCreate(
        //   idLesson,
        //   id.toString(),
        //   files[0]
        // );
        //const urlPhoto = `https://api.staging.online.brunoyam.com/portal/api/lesson/${idLesson}/attachment/${id}/data/`;
        const attachmentPhotoRes =
          await api.lesson.lessonAttachmentDataRetrieve(idLesson, String(id));
        const {
          data: { filename },
        } = attachmentPhotoRes;

        setData(state => ({
          ...state,
          [realId]: {
            ...state[realId],
            value: filename,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div className={s.main}>
      <div className={s.head}>Перенесите одно или несколько изображений</div>

      <div className={s.format}>Формат jpg, png, heic, не более 5 Мб.</div>

      <div
        className={s.dropZone}
        onDragStart={dragStartHandler}
        onDragOver={dragOverHandler}
        onDrop={onDropHandler}
        onDragEnd={handleDragEnd}
      >
        {isDragAndDrop ? (
          <div onDragStart={dragStartHandler} onDragEnd={handleDragEnd}>
            <DragAndDropCursor mousePos={mousePos} filesCount={filesCount} />
          </div>
        ) : (
          <button className={s.button} onClick={handleClick}>
            <input
              className={s.hidden}
              type={'file'}
              ref={inputRef}
              onChange={uploadFilesHandler}
              multiple
              accept={'image/jpeg,image/png,image/heic'}
              name={'photo'}
            />
            Выбрать изображения
          </button>
        )}
      </div>
    </div>
  );
};
