import cn from 'classnames';
import { ChatMessageProps } from './types';
import styles from './styles.module.scss';

export const ChatMessage = ({
  text,
  ownedBySender,
  time,
  className,
}: ChatMessageProps) => (
  <div
    className={cn(
      styles.wrapper,
      'size-s',
      ownedBySender ? styles['owned-by-sender'] : styles['owned-by-recipient'],
      className
    )}
  >
    <span className={styles.text}>{text}</span>
    {time && (
      <time
        className={cn(
          styles.time,
          'size-xxs',
          !ownedBySender && styles['recipient-time']
        )}
      >
        {time}
      </time>
    )}
  </div>
);
