import { useRef, useState, useEffect } from 'react';
import { Editor, Transforms, Path, Range, Element } from 'slate';

import { isBlockActive, Button } from './Button';
/* import s from "./Button.module.scss"; */

//This hook returns if the click was inside the popUp ref or outside it .
function usePopup(popupRef) {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleDocumentClick = e => {
      const clickedComponent = e.target;
      if (!popupRef?.current?.contains(clickedComponent)) {
        setShowPopup(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [showPopup, setShowPopup];
}

export const removeLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      //@ts-ignore
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
};

const createLinkNode = (href, showInNewTab, text) => ({
  type: 'link',
  href,
  target: '_blank', //showInNewTab ? "_blank" : "_self",
  children: [{ text }],
});

export const insertLink = (editor, { url, showInNewTab }) => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, showInNewTab, 'Link');
  if (!!selection) {
    const [parent, parentPath] = Editor.parent(editor, selection.focus.path);
    //@ts-ignore
    if (parent.type === 'link') {
      removeLink(editor);
    }

    if (editor.isVoid(parent)) {
      Transforms.insertNodes(
        editor,
        //@ts-ignore
        { type: 'paragraph', children: [link] }
        /*  {
          at: Path.next(parentPath),
          select: true,
        } */
      );
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
    }
  } else {
    //@ts-ignore
    Transforms.insertNodes(editor, { type: 'paragraph', children: [link] });
  }
};

//////////////////////////////////////////////////

const LinkButton = ({ active, editor, showInNewTab, setShowInput }) => {
  const linkInputRef = useRef(null);
  //const [showInput, setShowInput] = usePopup(linkInputRef);
  //  const [url, setUrl] = useState("");
  //const [showInNewTab, setShowInNewTab] = useState(false);
  const [selection, setSelection] = useState();
  /* const handleInsertLink = () => {
    Transforms.select(editor, selection);
    insertLink(editor, { url, showInNewTab });
    setUrl("");
    //@ts-ignore
    setShowInput((prev) => !prev);
    setShowInNewTab(false);
  }; */

  const toggleLink = () => {
    setSelection(editor.selection);
    //@ts-ignore
    setShowInput(prev => !prev);
  };

  /* const handleInputChange = ({ target }) => {
    if (target.type === "checkbox") {
      setShowInNewTab((prev) => !prev);
    } else {
      setUrl(target.value);
    }
  };
 */
  return (
    <div ref={linkInputRef} className='popup-wrapper'>
      <Button
        /* className={showInput ? "clicked" : ""} */
        active={isBlockActive(editor, 'link')}
        format={'link'}
        onClick={toggleLink}
      >
        <p>ссылка</p>
      </Button>
      {/*  {showInput && (
        <div className="popup">
          <div style={{ display: "flex", gap: "4px", margin: "5px 2px" }}>
            <input
              type="text"
              placeholder="https://google.com"
              value={url}
              onChange={handleInputChange}
            />
            <div onClick={handleInsertLink}>
              <p>добавить</p>
            </div>
          </div>
          <label>
            <input
              type="checkbox"
              checked={showInNewTab}
              onChange={handleInputChange}
            />
            <span style={{ fontSize: "0.8em" }}>Open in new tab</span>
          </label>
        </div>
      )} */}
    </div>
  );
};

export default LinkButton;
