import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useMediaQuery } from '@mui/material';
import { ModuleNested } from '../../../../api';
import { useAppSelector } from '../../../../redux/hooks';
import {
  userGroupSelector,
  userSelector,
} from '../../../../redux/user/selectors';
import {
  MEDIA_QUERY_MOBILE,
  MEDIA_QUERY_TABLET,
} from '../../../../shared/theme/mediaQuery';
import { useFocus } from '../../../../shared/hooks';
import { ReactComponent as LogoIcon } from '../../../../shared/icons/logo.svg';
import { ListEdge } from '../../../../shared/components/ListEdge';
import { COURSES_URL_PART } from '../../../../shared/constants/paths';
import { ReactComponent as CloseIcon } from './images/close.svg';
import { ReactComponent as ArrowDownIcon } from './images/arrow-down.svg';
import { Props } from './types';
import styles from './styles.module.scss';

export const Menu = ({ handleClose, content }: Props) => {
  const initialShownArray = new Array<boolean>(content.modules.length).fill(
    false
  );
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const userGroup = useAppSelector(userGroupSelector);
  const [shown, setShown] = useState(initialShownArray);
  const {
    moduleId: moduleNumber,
    lessonId: lessonNumber,
    courseName,
  } = useParams();

  const matchesMobile = useMediaQuery(MEDIA_QUERY_MOBILE);
  const matchesTablet = useMediaQuery(MEDIA_QUERY_TABLET);

  const handleMenuModule = (number: number) => {
    const newMenuItemsArray = [...shown];
    newMenuItemsArray[number] = !shown[number];
    setShown(newMenuItemsArray);
  };

  const handleMenuLesson = (
    moduleIndex: number,
    lessonIndex: number,
    courseName: string
  ) => {
    if (!isLessonClosed(moduleIndex, lessonIndex)) {
      navigate(
        `/${COURSES_URL_PART}/${courseName}/module-${moduleIndex + 1}/lesson-${
          lessonIndex + 1
        }`
      );
      handleClose();
    }
  };

  const [focusRef, setFocus] = useFocus();

  useEffect(() => {
    if (typeof setFocus === 'function') {
      setFocus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isLessonClosed = (moduleIndex: number, lessonIndex: number) => {
    if (user) {
      if (userGroup === 'student') {
        const modulePosition = user.active_course.module.position;
        const lessonPosition = user.active_course.lesson.position;

        if (moduleIndex + 1 > modulePosition) {
          return true;
        }

        if (moduleIndex + 1 < modulePosition) {
          return false;
        }

        if (moduleIndex + 1 === modulePosition) {
          return lessonIndex + 1 > lessonPosition;
        }
      } else {
        return false;
      }
    }
  };

  const calcLessonsDoneInModule = (module: ModuleNested) => {
    if (user) {
      if (userGroup === 'student') {
        if (module.position < user.active_course.module.position) {
          return module.lessons.length;
        }

        if (module.position > user.active_course.module.position) {
          return 0;
        }

        if (module.position === user.active_course.module.position) {
          return user.active_course.lesson.position - 1;
        }
      } else {
        return module.lessons.length;
      }
    }
  };

  return (
    user && (
      <aside
        className={cn(styles.menu, {
          [styles['menu-mobile']]: matchesMobile && !matchesTablet,
        })}
        tabIndex={-1}
        onBlur={handleClose}
        ref={focusRef}
      >
        <CloseIcon className={styles.close} onClick={handleClose} />
        <LogoIcon className={styles.logo} onClick={() => navigate('/')} />
        <h2 className={styles.title}>{content.name}</h2>
        <ul className={styles.list}>
          {content.modules.map((trainingModule, moduleIndex) => (
            <li className={styles['module-item']} key={moduleIndex}>
              <div
                className={styles['module-row']}
                onClick={() => handleMenuModule(moduleIndex)}
              >
                <p className={styles['module-name']}>
                  {moduleIndex + 1}. {trainingModule.name}
                </p>
                <div className={styles['module-lessons-total']}>
                  <p className='size-xs'>{`${calcLessonsDoneInModule(
                    trainingModule
                  )}/${trainingModule.lessons.length}`}</p>
                  <ArrowDownIcon
                    className={cn({
                      [styles.rotated]: shown[moduleIndex],
                    })}
                  />
                </div>
              </div>
              {shown[moduleIndex] && (
                <ol className={styles['lessons-list']}>
                  {trainingModule.lessons.map((lesson, lessonIndex) => (
                    <li
                      className={cn(styles['lesson-name'], {
                        [styles.closed]: isLessonClosed(
                          moduleIndex,
                          lessonIndex
                        ),
                      })}
                      key={lessonIndex}
                      onClick={() =>
                        handleMenuLesson(
                          moduleIndex,
                          lessonIndex,
                          `${courseName}`
                        )
                      }
                    >
                      {`${lessonIndex + 1}. ${lesson.name}`}
                      {moduleNumber &&
                        lessonNumber &&
                        +moduleNumber === moduleIndex + 1 &&
                        +lessonNumber === lessonIndex + 1 && (
                          <>
                            <ListEdge className={styles.edge} />
                            <p
                              className={cn(styles.pointer, 'size-s')}
                            >{`•\u00A0Вы\u00A0здесь`}</p>
                          </>
                        )}
                    </li>
                  ))}
                </ol>
              )}
            </li>
          ))}
        </ul>
      </aside>
    )
  );
};
