import { Course, Message } from '../../../api';

/* Используем только для страницы чатов студента */
export const getChatPreviewLastMessage = (
  course: Course,
  messages: Message[]
) =>
  messages.find(
    message =>
      message.sender.id === course.mentor.id && message.course.id === course.id
  );

export const getLastMessageDate = (createdAt: string) => {
  if (createdAt) {
    const dateFromTimestamp = new Date(createdAt);

    try {
      const date = dateFromTimestamp.toLocaleDateString();
      const hours = dateFromTimestamp.getHours();
      const minutes = dateFromTimestamp.getMinutes();

      return `${date}, ${hours}:${minutes}`;
    } catch (error) {
      return '';
    }
  }
};
