import { Fragment, useEffect, useRef, useState } from 'react';
import { Modal } from '@mui/material';
import { api, PaginatedMessageList } from '../../../../../api';
import { StyledTextField } from '../../../mui-styled/TextField';
import {
  chatInterlocutorSelector,
  chatUnreadMessagesSelector,
  isChatShownSelector,
} from '../../../../../redux/view/chat/selectors';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { closeChatModal } from '../../../../../redux/view/chat/reducer';
import {
  userGroupSelector,
  userIdSelector,
} from '../../../../../redux/user/selectors';
import { ReactComponent as CloseIcon } from '../../../../icons/close.svg';
import { ReactComponent as SendIcon } from '../../../../icons/send.svg';
import { ChatMessage } from './ChatMessage';
import { CHAT_MESSAGES_REQUEST_LIMIT } from './constants';
import { formatToDateString } from './utils';
import styles from './styles.module.scss';

export const Chat = () => {
  // TODO: Скорее всего часть этих состояний правильнее хранить прямо в компоненте, а не в сторе
  const interlocutor = useAppSelector(chatInterlocutorSelector);
  const isChatShown = useAppSelector(isChatShownSelector);
  const currentUserId = useAppSelector(userIdSelector);
  const userGroup = useAppSelector(userGroupSelector);
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const unreadMessagesDataInState = useAppSelector(chatUnreadMessagesSelector);

  const [inputValue, setInputValue] = useState('');

  const messagesBottomEdge = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const [messagesHistory, setMessagesHistory] =
    useState<PaginatedMessageList>();

  const getMessagesHistory = async () => {
    const response = await api.message.messageList({
      course__lesson__module__course: interlocutor?.courseId,
      limit: CHAT_MESSAGES_REQUEST_LIMIT,
    });
    response.ok && setMessagesHistory(response.data);
  };

  const handleChatUpdate = async () => {
    await getMessagesHistory();
    setTimeout(() => {
      messagesBottomEdge?.current?.scrollIntoView();
    }, 0);
  };

  useEffect(() => {
    if (isChatShown) {
      handleChatUpdate();
    }
  }, [isChatShown]);

  useEffect(() => {
    if (
      unreadMessagesDataInState?.[0]?.id !== messagesHistory?.results?.[0]?.id
    ) {
      handleChatUpdate();
    }
  }, [unreadMessagesDataInState]);

  const handleClose = () => {
    dispatch(closeChatModal());
    setMessagesHistory({});
  };

  const handleMessageRequest = async () => {
    setIsInputDisabled(true);

    await api.message.messageCreate({
      text: inputValue,
      recipient: interlocutor.id,
      student: userGroup === 'student' ? currentUserId : interlocutor.id,
      course: interlocutor.courseId,
    });

    await getMessagesHistory();

    messagesBottomEdge?.current?.scrollIntoView();
    setIsInputDisabled(false);
  };

  const sendMessageByEnter = async e => {
    if (e.key === 'Enter') {
      await handleMessageRequest();
      setInputValue('');
    }
  };

  const sendMessageByButton = async () => {
    await handleMessageRequest();
    setInputValue('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <Modal open={isChatShown} onClose={handleClose} disableAutoFocus>
      <div className={styles.wrapper}>
        <CloseIcon className={styles['button-close']} onClick={handleClose} />
        {interlocutor?.id && (
          <div className={styles.interlocutor}>
            <h1>{`${interlocutor?.name} ${interlocutor?.surname}`}</h1>
            <span className={styles['interlocutor-course']}>{`${
              userGroup === 'student' ? 'Наставник' : 'Студент'
            } курса «${interlocutor?.courseName}»`}</span>
          </div>
        )}
        <div className={styles.dialog}>
          <div className={styles['messages-history']}>
            {!!messagesHistory?.results?.length &&
              [...messagesHistory?.results]
                ?.reverse()
                .map((message, index, messages) => {
                  const messageDate = new Date(message.created_at);
                  let dateTag = formatToDateString(messageDate);

                  if (
                    index !== 0 &&
                    dateTag ===
                      formatToDateString(
                        new Date(messages[index - 1].created_at)
                      )
                  ) {
                    dateTag = null;
                  }

                  return (
                    <Fragment key={message.id}>
                      {dateTag && (
                        <span className={styles['date-tag']}>{dateTag}</span>
                      )}
                      <ChatMessage
                        text={message.text}
                        ownedBySender={message.sender.id === currentUserId}
                        time={messageDate.toLocaleTimeString().slice(0, -3)}
                        className={styles['chat-message']}
                      />
                    </Fragment>
                  );
                })}
            <div ref={messagesBottomEdge} />
          </div>
          <footer className={styles.footer}>
            <StyledTextField
              autoFocus={true}
              value={inputValue}
              onChange={handleInputChange}
              size='small'
              className={styles.input}
              onKeyDown={sendMessageByEnter}
              disabled={isInputDisabled}
            />
            <SendIcon
              id='send-icon'
              className={styles['button-send']}
              onClick={sendMessageByButton}
            />
          </footer>
        </div>
      </div>
    </Modal>
  );
};
