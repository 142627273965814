import React from 'react';
import { useTheme } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import cn from 'classnames';

import { MatchingTask } from './MatchingTask';
import { TestTask } from './TestTask';
import { DetailedAnswerTask } from './DetailedAnswerTask';
import { Video } from './Video';
import { MARKDOWN_OVERRIDED_COMPONENTS } from './MarkdownComponents';
import { Content as ContentType } from '../../../api/lesson-content/lessonContentType';
import styles from './styles.module.scss';

type TProps = {
  content: ContentType;
  matchesTablet: Boolean;
};

export const CurrentLessonContent = ({ content, matchesTablet }: TProps) => {
  const theme = useTheme();

  return (
    <>
      {content?.map((component, index) => {
        const { type } = component;
        if (type === 'text') {
          return (
            <Markdown
              options={{
                wrapper: 'section',
                forceBlock: true,
                overrides: MARKDOWN_OVERRIDED_COMPONENTS,
              }}
              key={index}
              className={cn(styles['text-block'], {
                [styles['with-border']]: component.isImportant,
                [styles['important']]: component.isImportant,
                [styles['important-mobile']]:
                  component.isImportant && !matchesTablet,
                [styles[theme.name]]: component.isImportant,
              })}
            >
              {component.text}
            </Markdown>
          );
        } else if (type === 'video') {
          return <Video component={component} key={index} />;
        } else if (type === 'test_task') {
          return (
            <TestTask
              component={component}
              key={index}
              className={cn({
                [styles['with-border-mobile']]: !matchesTablet,
              })}
            />
          );
        } else if (type === 'matching_task') {
          return (
            <MatchingTask
              component={component}
              key={index}
              className={cn({
                [styles['with-border-mobile']]: !matchesTablet,
              })}
            />
          );
        } else if (type === 'detailed_answer_task') {
          return (
            <DetailedAnswerTask
              component={component}
              key={index}
              className={cn({
                [styles['with-border-mobile']]: !matchesTablet,
              })}
            />
          );
        } else if (type === 'project_task') {
          return (
            <DetailedAnswerTask
              component={component}
              key={index}
              withFileUpload
              className={cn({
                [styles['with-border-mobile']]: !matchesTablet,
              })}
            />
          );
        }

        return <></>;
      })}
    </>
  );
};
