import cn from 'classnames';
import { ChatPreviewProps } from './types';
import styles from './styles.module.scss';

export const ChatPreview = ({
  fullName,
  courseName,
  lastMessageText,
  lastMessageDate,
  onClick,
}: ChatPreviewProps) => {
  return (
    <div className={styles.wrapper} onClick={onClick}>
      <p className={cn(styles['full-name'], 'size-s')}>{fullName}</p>
      <p className={cn(styles['course-name'], 'size-xxs')}>{courseName}</p>
      {lastMessageText && (
        <p className={cn(styles.text, 'size-xs')}>{lastMessageText}</p>
      )}
      {lastMessageDate && (
        <p className={cn(styles.date, 'size-xxs')}>{lastMessageDate}</p>
      )}
    </div>
  );
};
