import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import Markdown from 'markdown-to-jsx';
import { useMediaQuery } from '@mui/material';

import { api } from '../../../../api';
import { Button } from '../../../../shared/components/Button';
import { StyledTextField } from '../../../../shared/components/mui-styled/TextField';
import { MEDIA_QUERY_TABLET } from '../../../../shared/theme/mediaQuery';
import {
  openChatModal,
  setInterlocutor,
} from '../../../../redux/view/chat/reducer';
import { useAppSelector } from '../../../../redux/hooks';
import { courseSelector } from '../../../../redux/course/selectors';
import { ReactComponent as DialogIcon } from '../../../../shared/icons/dialog.svg';
import { MARKDOWN_OVERRIDED_COMPONENTS } from '../MarkdownComponents';
import { FileUpload } from './FileUpload';
import {
  TaskStatus,
  Status,
  ACCEPTED_STATUS,
  IN_PROGRESS_STATUS,
  REJECTED_STATUS,
} from './TaskStatus';
import { ANSWER_EMPTY, BUTTON_TEXT } from './constants';
import { TestTaskProps } from './types';
import contentStyles from '../styles.module.scss';
import styles from './styles.module.scss';

export const DetailedAnswerTask = ({
  component: { id, header, content },
  withFileUpload,
  className,
}: TestTaskProps) => {
  const [textFieldContent, setTextFieldContent] = useState('');
  const [messageToUser, setMessageToUser] = useState('');
  const [isEachInputDisabled, setIsEachInputDisabled] = useState(true);
  const [taskResultStatus, setTaskResultStatus] = useState<Status>(null);

  const activeCourse = useAppSelector(courseSelector);

  const dispatch = useDispatch();

  const matchesTablet = useMediaQuery(MEDIA_QUERY_TABLET);

  useEffect(() => {
    const asyncEffect = async (taskId: number) => {
      try {
        const response = await api.taskResult.taskResultList({
          task: String(taskId),
          ordering: '-created_at',
          limit: 1,
          with_content: true,
        });
        const { results } = response.data;

        const responseResultsFirst = results?.[0];
        const responseContent = responseResultsFirst?.content;
        const responseTaskStatus = responseResultsFirst?.accepted;

        if (responseTaskStatus) {
          setTaskResultStatus(ACCEPTED_STATUS);
        } else if (responseTaskStatus === false) {
          setTaskResultStatus(REJECTED_STATUS);
        } else if (responseTaskStatus === null) {
          setTaskResultStatus(IN_PROGRESS_STATUS);
        }

        if (!responseResultsFirst || responseTaskStatus === false) {
          setIsEachInputDisabled(false);
        }

        if (responseContent) {
          const lastAnswer = JSON.parse(responseContent).text;
          setTextFieldContent(lastAnswer);
        }
      } catch (e) {
        console.error(e);
      }
    };

    asyncEffect(id);
  }, [id, api.taskResult.taskResultList]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setTextFieldContent(value);
    },
    []
  );

  const handleButtonClick = async () => {
    try {
      if (textFieldContent) {
        const response = await api.taskResult.taskResultCreate({
          task: id,
          content: JSON.stringify({
            text: textFieldContent,
          }),
        });

        setIsEachInputDisabled(true);
        setTaskResultStatus(IN_PROGRESS_STATUS);

        return response.data.id;
      } else {
        setMessageToUser(ANSWER_EMPTY);
        throw new Error('Textfield content is empty');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleChatClick = useCallback(() => {
    dispatch(
      setInterlocutor({
        id: activeCourse.mentor?.id,
        name: activeCourse.mentor?.['first_name'],
        surname: activeCourse.mentor?.['last_name'],
        courseName: activeCourse.name,
        courseId: activeCourse.id,
        isStudent: false,
      })
    );
    dispatch(openChatModal());
  }, [dispatch]);

  const isLengthContent = content.length;

  return (
    <section
      className={cn(
        styles['detailed-task'],
        contentStyles['with-border'],
        className
      )}
    >
      {header && <h2 className={styles['header-callout']}>{header}</h2>}
      {isLengthContent && (
        <Markdown
          options={{
            wrapper: 'section',
            forceBlock: true,
            overrides: MARKDOWN_OVERRIDED_COMPONENTS,
          }}
        >
          {content[0]}
        </Markdown>
      )}
      <h4 className={styles.subheader}>Ваш ответ</h4>
      <StyledTextField
        value={textFieldContent}
        onChange={handleChange}
        className={styles['test-input']}
        minRows={!matchesTablet ? 9 : 4}
        multiline
        disabled={isEachInputDisabled}
      />
      <p className={styles.answer}>{messageToUser}</p>
      <div
        className={cn({
          [styles['with-bottom-margin']]: taskResultStatus,
          [styles['with-bottom-margin-mobile']]: !matchesTablet,
        })}
      >
        {withFileUpload && id && !isEachInputDisabled ? (
          <FileUpload
            labelId={id}
            onClick={handleButtonClick}
            handleChatClick={handleChatClick}
          />
        ) : (
          <div className={contentStyles['task-buttons']}>
            <Button
              withTheme
              onClick={handleButtonClick}
              disabled={isEachInputDisabled}
            >
              {BUTTON_TEXT}
            </Button>
            <button
              onClick={handleChatClick}
              className={cn(styles.button, 'size-m')}
            >
              <DialogIcon />
              Задать вопрос
            </button>
          </div>
        )}
      </div>
      {taskResultStatus && <TaskStatus status={taskResultStatus} />}
    </section>
  );
};
