import React from 'react';
import cn from 'classnames';

import { Plus, Back } from './icons';
import { tagsRUHash } from '../../../pages/AllCourses/constants';
import styles from './CardContainer.module.scss';

const pricePorrection = (price: number) => {
  return price.toLocaleString('ru-RU', { useGrouping: true });
};

interface IProps {
  isUserManager: boolean;
  isModules?: boolean;
  isMobile?: boolean;
  title: string;
  tag?: string;
  hours?: number;
  price?: number;
  numberModule?: number;
  countLesson?: number;
  setIsOpenModalAdd: (state: boolean) => void;
  handleBackPage?: () => void;
  children?: React.ReactNode;
}

const CardContainer = ({
  isUserManager,
  isModules,
  isMobile,
  title,
  tag,
  hours,
  price,
  numberModule,
  countLesson,
  setIsOpenModalAdd,
  handleBackPage,
  children,
}: IProps) => {
  const handleClick = () => setIsOpenModalAdd(true);

  return (
    <div className={styles.block}>
      {isMobile && (
        <div className={styles.buttonBack} onClick={handleBackPage}>
          <Back /> {isModules ? 'Все курсы' : 'Все модули'}
        </div>
      )}

      <div className={styles.topRow}>
        <div className={styles.title}>{title}</div>
        {isUserManager && (
          <div className={styles.addedButton} onClick={handleClick}>
            <Plus />
          </div>
        )}
      </div>

      {isModules ? (
        <div className={styles.secondRow}>
          <span className={cn(styles.tag, styles[`tag-${tag}`])}>
            {tagsRUHash[tag]}
          </span>
          <div className={styles.hours}>
            <b>{hours}</b> ак.ч.
          </div>
          <div className={styles.price}>
            <b>{pricePorrection(price)}</b> ₽
          </div>
        </div>
      ) : (
        <div className={styles.des}>
          Модуль {numberModule}, {countLesson} урока.
        </div>
      )}

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default CardContainer;
