import { useEffect } from 'react';
import { api } from '../../../api';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userIdSelector } from '../../../redux/user/selectors';
import { setUnreadMessages } from '../../../redux/view/chat/reducer';
import { chatUnreadMessagesSelector } from '../../../redux/view/chat/selectors';
import { Chat } from './components/Chat/Chat';
import { ChatProviderProps } from './types';

export const ChatProvider = ({
  observing = true,
  children,
}: ChatProviderProps) => {
  const dispatch = useAppDispatch();

  const unreadMessagesDataInState = useAppSelector(chatUnreadMessagesSelector);
  const currentUserId = useAppSelector(userIdSelector);

  const setMessagesState = async () => {
    const unreadMessages = await api.message.messageList({
      was_read: false,
      recipient: currentUserId,
    });

    const unreadMessagesDataFromResponse = unreadMessages.data;

    if (!unreadMessagesDataInState.length) {
      dispatch(setUnreadMessages(unreadMessagesDataFromResponse.results));
      return;
    }

    if (
      unreadMessagesDataInState?.[0]?.id !==
      unreadMessagesDataFromResponse.results?.[0]?.id
    ) {
      dispatch(setUnreadMessages(unreadMessagesDataFromResponse.results));
    }
  };

  useEffect(() => {
    setMessagesState();
  }, []);

  /* useEffect(() => {
    let newMessagesObserver: NodeJS.Timeout;

    if (observing) {
      newMessagesObserver = setInterval(async () => {
        setMessagesState();
      }, 3000);
    }

    return () => clearInterval(newMessagesObserver);
  }, [observing, currentUserId, unreadMessagesDataInState.length]); */

  return (
    <>
      {children}
      <Chat />
    </>
  );
};
