import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import LessonsChangeContent from './LessonsChangeContent';
import { IDataCourse } from '../Modules/Modules';
import { Navigation } from '../../shared/components/Navigation';
import { api, LessonFull } from '../../api';
import { Content as TContent } from '../../api/lesson-content/lessonContentType';
import { TDataChange } from './LessonsChangeContent/types';
import { useFormatDataForSave } from './hooks';
import { StatusBlock } from './Status';
import { ID_RESPONSE_ERROR, ID_RESPONSE_APPROVE, MANAGER } from './constans';
import { useAppSelector } from '../../redux/hooks';
import { userGroupSelector } from '../../redux/user/selectors';

import styles from './LessonsChange.module.scss';

const LessonsChange = () => {
  const navigate = useNavigate();
  const userGroup = useAppSelector(userGroupSelector);
  if (userGroup !== MANAGER) {
    navigate('/'); // если пользователь не менеджер, редирект на главую
  }

  const search = useLocation().search;
  const idCourse = new URLSearchParams(search).get('course-id');
  const idLesson = new URLSearchParams(search).get('lesson-id');

  const [dataCourse, setDataCourse] = useState<IDataCourse | null>(null);
  const [dataContent, setDataContent] = useState<TContent>(null);
  const [dataLesson, setDataLesson] = useState<LessonFull>(null);
  const [dataChange, setDataChange] = useState<TDataChange>({});

  const [statusResponse, setStatusResponse] = useState('');

  const { formatDataSave } = useFormatDataForSave();

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const res = await api.course.courseRetrieve(+idCourse);
        const { data } = res;

        setDataCourse(data);
      } catch (e) {
        console.error(e);
      }
    };

    asyncEffect();
  }, [idCourse, api.course.courseRetrieve]);

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const res = await api.lesson.lessonRetrieve(+idLesson);
        const { data } = res;
        const dataContentPars = JSON.parse(data.content);

        setDataLesson(data);
        setDataContent(dataContentPars);
      } catch (e) {
        console.error(e);
      }
    };

    asyncEffect();
  }, [idLesson, api.lesson.lessonRetrieve]);

  const handleUpdate = async () => {
    try {
      const data = formatDataSave({ data: dataChange });

      const dataJson = JSON.stringify(data);

      const obj = {
        ...dataLesson,
        description: dataLesson.description,
        content: dataJson,
      };

      await api.lesson.lessonPartialUpdate(dataLesson.id, obj);
      setStatusResponse(ID_RESPONSE_APPROVE);
    } catch (e) {
      setStatusResponse(ID_RESPONSE_ERROR);
      console.error(e);
    }
  };

  return (
    <div>
      <Navigation
        isNotVisibleLeftSideBar={true}
        isVisibleMenuDetailedInformation={true}
        infoModule={dataCourse}
      />

      <div className={styles.buttonBlock}>
        <button onClick={handleUpdate}>Опубликовать</button>
      </div>

      {statusResponse && (
        <StatusBlock status={statusResponse} setStatus={setStatusResponse} />
      )}

      {dataCourse && (
        <div className={styles.content}>
          <LessonsChangeContent
            dataCourse={dataCourse}
            dataContent={dataContent}
            dataChange={dataChange}
            setDataChange={setDataChange}
          />
        </div>
      )}
    </div>
  );
};

export default LessonsChange;
