import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';

import { useAppSelector } from '../../redux/hooks';
import { userSelector } from '../../redux/user/selectors';
import { COURSES_URL_PART } from '../../shared/constants/paths';
import { Navigation } from '../../shared/components/Navigation';
import CardContainer, {
  ModalAdd,
  ModalChange,
  CardContainerItem,
  ModalDelete,
} from '../../shared/components/CardContainer';
import { api, ModuleNested, User } from '../../api';
import { Popup } from '../../shared/components';

const MAX_WIDTH_MOBILE = 800;
const IS_MODULES = true;

type TLessons = {
  id: number;
  module: number;
  name: string;
  description: string;
  position: number;
};

type TModule = {
  course: number;
  description: string;
  id: number;
  lessons: TLessons[];
  name: string;
  position: number;
};

export interface IDataCourse {
  description: string;
  hours: number;
  id: number;
  // mentor: null | string;
  mentor: null | User;
  mentors?: [];
  // modules: TModule[];
  modules: ModuleNested[];
  name: string;
  price: number;
  tags: string[];
}

const Modules = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const idCourse = new URLSearchParams(search).get('course-id');

  const user = useAppSelector(userSelector);
  const isUserManager = useMemo(
    () => user && user.groups.some(item => item === 'manager'),
    [user]
  );

  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const [isOpenModalChange, setIsOpenModalChange] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const [isUpdate, setIsUpdate] = useState(true);

  const [dataCourse, setDataCourse] = useState<IDataCourse>(null);

  const [selectedModuleId, setSelectedModuleId] = useState<number>(null);
  const selectedModule = useMemo(
    () => dataCourse?.modules.find(item => item.id === selectedModuleId),
    [selectedModuleId, dataCourse]
  );

  const { width, ref } = useResizeDetector();
  const isMobile = useMemo(() => width <= MAX_WIDTH_MOBILE, [width]);

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const res = await api.course.courseRetrieve(+idCourse);
        const { data } = res;

        setDataCourse(data);
      } catch (e) {
        console.error(e);
      }
    };

    if (isUpdate) {
      asyncEffect();
      setIsUpdate(false);
    }
  }, [idCourse, api.course.courseRetrieve, isUpdate]);

  const handleBackPage = () => {
    navigate(`/${COURSES_URL_PART}/all`);
  };

  const saveNewModule = async (name: string, description: string) => {
    try {
      const module = {
        course: parseInt(idCourse, 10),
        name,
        description,
        position: dataCourse.modules.length + 1,
      };

      await api.module.moduleCreate(module);
      setIsUpdate(true);
      setIsOpenModalAdd(false);
    } catch (e) {
      console.error(e);
    }
  };

  const changeModule = async (name: string, description: string) => {
    try {
      const changedModule = { ...selectedModule, name, description };

      await api.module.moduleUpdate(selectedModuleId, changedModule);
      setIsUpdate(true);
      setIsOpenModalChange(false);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteModule = async () => {
    try {
      await api.module.moduleDestroy(selectedModuleId);
      setIsUpdate(true);
      setIsOpenModalDelete(false);
    } catch (e) {
      console.error(e);
    }
  };

  const getLessons = moduleId => {
    navigate(
      `/${COURSES_URL_PART}/all/modules/lessons?course-id=${idCourse}&module-id=${moduleId}`
    );
  };

  return (
    <div ref={ref}>
      <Navigation
        isModules={IS_MODULES}
        isMobile={isMobile}
        handleBackPage={handleBackPage}
      />

      <CardContainer
        isUserManager={isUserManager}
        isMobile={isMobile}
        isModules={IS_MODULES}
        title={dataCourse?.name || ''}
        tag={dataCourse?.tags[0] || ''}
        hours={dataCourse?.hours || 0}
        price={dataCourse?.price || 0}
        setIsOpenModalAdd={setIsOpenModalAdd}
        handleBackPage={handleBackPage}
      >
        {dataCourse?.modules.map(item => {
          const { id, name, description, position } = item;

          return (
            <CardContainerItem
              key={id}
              isUserManager={isUserManager}
              id={id}
              name={name}
              description={description}
              position={position}
              setSelectedItemId={setSelectedModuleId}
              setIsOpenModalChange={setIsOpenModalChange}
              setIsOpenModalDelete={setIsOpenModalDelete}
              getLessons={getLessons}
            />
          );
        })}
      </CardContainer>

      <Popup opened={isOpenModalAdd} setOpened={setIsOpenModalAdd}>
        <ModalAdd
          isModules={IS_MODULES}
          isMobile={isMobile}
          setIsOpenModalAdd={setIsOpenModalAdd}
          handleSave={saveNewModule}
        />
      </Popup>
      <Popup opened={isOpenModalChange} setOpened={setIsOpenModalChange}>
        <ModalChange
          isModules={IS_MODULES}
          isMobile={isMobile}
          setIsOpenModalChange={setIsOpenModalChange}
          handleSave={changeModule}
          selectedItem={selectedModule}
        />
      </Popup>
      <Popup opened={isOpenModalDelete} setOpened={setIsOpenModalDelete}>
        <ModalDelete
          name={selectedModule?.name}
          setIsOpenModalChange={setIsOpenModalDelete}
          deleteModule={deleteModule}
        />
      </Popup>
    </div>
  );
};

export default Modules;
