import { AppState } from '../types';
//import { TagValue } from "./types";

export const coursesListSelector = (state: AppState) => state.coursesList.data;

export const coursesByTagSelector = (state: AppState, tag: string) => {
  const courses = state.coursesList.data?.results;

  if (!tag) {
    return courses;
  } else if (courses?.length && courses?.length > 1) {
    return state.coursesList.data?.results?.filter(course =>
      course.tags.includes(tag)
    );
  }
};
