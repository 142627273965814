export const formatToDateString = (date: Date) => {
  return `${date.getDay()} ${formatMonth(date.getMonth())}`;
};

const formatMonth = (month: number) => {
  switch (month) {
    case 0:
      return 'января';
    case 1:
      return 'февраля';
    case 2:
      return 'марта';
    case 3:
      return 'апреля';
    case 4:
      return 'мая';
    case 5:
      return 'июня';
    case 6:
      return 'июля';
    case 7:
      return 'августа';
    case 8:
      return 'сентября';
    case 9:
      return 'октября';
    case 10:
      return 'ноября';
    case 11:
      return 'декабря';

    default:
      return '';
  }
};
