export const options = {
  textBlocks: [
    {
      id: 'paragaph',
      name: 'Текстовый параграф',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.2857 7.42859H15.7143V9.14285H19.1429V4H2V9.14285H5.42859V7.42859H8.85718V24.5714H5.42859V28H15.7126V24.5714H12.2857V7.42859Z'
            fill='#59647A'
          />
          <path
            d='M15.7144 12.5715V17.7143H19.1429V16.0001H20.8572V24.5714H19.1429V28H26.0001V24.5714H24.2858V16H26.0001V17.7143H29.4286V12.5714H15.7144V12.5715Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'unnumberedList',
      name: 'Ненумерованный список',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M31.0001 22.1428H9.57153V26.4285H31.0001V22.1428Z'
            fill='#59647A'
          />
          <path
            d='M31.0001 13.7013H9.57153V17.987H31.0001V13.7013Z'
            fill='#59647A'
          />
          <path d='M31.0001 5H9.57153V9.28572H31.0001V5Z' fill='#59647A' />
          <path
            d='M5.28572 22.2727H1V26.5584H5.28572V22.2727Z'
            fill='#59647A'
          />
          <path d='M5.28572 13.7013H1V17.987H5.28572V13.7013Z' fill='#59647A' />
          <path d='M5.28572 5H1V9.28572H5.28572V5Z' fill='#59647A' />
        </svg>
      ),
    },
    {
      id: 'numberedList',
      name: 'Нумерованный список',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M3.08734 17.9461L3.07413 17.8954L3.93247 16.964C4.49019 16.357 4.87264 15.8933 5.08045 15.5854C5.28798 15.2776 5.39182 14.9202 5.39182 14.5195C5.39182 13.9241 5.19646 13.4536 4.80597 13.1107C4.41536 12.7682 3.8715 12.5961 3.17451 12.5961C2.51817 12.5961 1.99099 12.7997 1.5931 13.2077C1.19521 13.6156 1.00349 14.1067 1.01811 14.6934L1.02692 14.7133H2.46442V14.7134C2.46442 14.4548 2.52763 14.1901 2.65397 14.0029C2.78032 13.8155 2.95383 13.725 3.17457 13.725C3.42139 13.725 3.60659 13.799 3.73006 13.9441C3.85341 14.0894 3.9152 14.2849 3.9152 14.5289C3.9152 14.7002 3.85417 14.9024 3.73223 15.1346C3.61029 15.367 3.39631 15.6314 3.13768 15.9276L1.07515 18.0714V18.9805H5.47153V17.9461H3.08734Z'
            fill='#59647A'
          />
          <path
            d='M4.04917 8.24821V3L1.0752 3.4443V4.49836H2.49754V8.24821H1.0752V9.41193H5.47152V8.24821H4.04917Z'
            fill='#59647A'
          />
          <path
            d='M5.29175 25.8676C5.09997 25.6133 4.83424 25.4246 4.49444 25.2997C4.79354 25.1631 5.03248 24.9737 5.21105 24.7313C5.38973 24.4888 5.47895 24.2236 5.47895 23.9363C5.47895 23.3584 5.27272 22.9118 4.86038 22.5968C4.44804 22.2815 3.89772 22.1241 3.20931 22.1241C2.61681 22.1241 2.11295 22.281 1.69762 22.5947C1.28228 22.9084 1.08327 23.3404 1.10078 23.8457L1.10959 23.8945H2.53851C2.53851 23.6358 2.61094 23.5553 2.75626 23.4449C2.90146 23.3344 3.06998 23.2681 3.26158 23.2681C3.50558 23.2681 3.69137 23.3307 3.8193 23.4673C3.94706 23.6038 4.01091 23.7693 4.01091 23.9698C4.01091 24.2224 3.93977 24.4319 3.79757 24.5742C3.65519 24.7166 3.45195 24.7996 3.18763 24.7996H2.49751V25.8341H3.18763C3.48103 25.8341 3.70823 25.9207 3.86947 26.0615C4.0307 26.2024 4.11129 26.4349 4.11129 26.7427C4.11129 26.9635 4.03423 27.1505 3.88051 27.2957C3.7265 27.4409 3.52027 27.5154 3.26176 27.5154C3.03521 27.5154 2.84295 27.4178 2.68454 27.2799C2.52612 27.142 2.44712 26.9978 2.44712 26.7392H1.00932L1.00069 26.795C0.986178 27.3875 1.20175 27.8548 1.64757 28.167C2.09339 28.4791 2.61399 28.6427 3.20931 28.6427C3.9006 28.6427 4.46836 28.4803 4.91277 28.1477C5.35719 27.8152 5.57922 27.3603 5.57922 26.7794C5.57922 26.425 5.48354 26.1218 5.29175 25.8676Z'
            fill='#59647A'
          />
          <path
            d='M31.0734 22.0833H9.73804V26.3504H31.0734V22.0833Z'
            fill='#59647A'
          />
          <path
            d='M31.0734 13.6785H9.73804V17.9455H31.0734V13.6785Z'
            fill='#59647A'
          />
          <path
            d='M31.0734 5.01562H9.73804V9.28267H31.0734V5.01562Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'h1',
      name: 'Заголовок 1',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20.991 22.9054V24.8629C20.9838 25.5898 20.5952 25.9928 19.8683 25.9928C17.9467 26 16.0252 26 14.1108 25.9928C13.4055 25.9928 13.0097 25.597 13.0025 24.8845C12.9953 24.2224 12.9953 23.5603 13.0025 22.8982C13.0097 22.2792 13.2976 21.9986 13.9237 21.9914H14.9816V18.0115H7.02195V21.9986H8.07989C8.70601 22.0058 8.99388 22.2864 9.00108 22.9054C9.00828 23.5675 9.00828 24.2296 9.00108 24.8917C8.99388 25.6042 8.60525 26 7.89277 26C5.97121 26 4.04966 26 2.1353 26C1.40842 26 1.01979 25.6042 1.01259 24.8701C1.0054 24.2152 1.0054 23.5603 1.0054 22.9054C1.0054 22.3224 1.32206 21.9986 1.905 21.9914C2.26484 21.9842 2.62469 21.9914 2.97733 21.9914V9.97985C2.5815 9.97985 2.21447 9.98705 1.84743 9.97985C1.32206 9.97265 1.01259 9.65599 1.0054 9.13782C0.998201 8.44692 0.998201 7.75603 1.0054 7.06513C1.01259 6.39583 1.41562 6 2.09212 6H8.08708C8.69162 6 8.98669 6.28787 8.99388 6.89241C9.00108 7.62648 9.00108 8.35336 8.99388 9.08744C8.98669 9.69198 8.69881 9.98705 8.09428 9.98705C7.73444 9.98705 7.38179 9.98705 7.02195 9.98705V13.9813H14.9673V9.98705C14.6074 9.98705 14.2548 9.98705 13.8949 9.98705C13.2904 9.97985 13.0025 9.69198 12.9953 9.08744C12.9881 8.35336 12.9881 7.62648 12.9953 6.89241C13.0025 6.28787 13.2976 6 13.9021 6H19.8971C20.5736 6 20.9766 6.39583 20.9838 7.06513C20.991 7.75603 20.991 8.44692 20.9838 9.13782C20.9766 9.66319 20.6671 9.97265 20.1418 9.97985C19.7747 9.98705 19.4005 9.97985 19.0119 9.97985V21.9914H20.0842C20.6671 22.0058 20.9838 22.3224 20.991 22.9054Z'
            fill='#59647A'
          />
          <path
            d='M27.6382 24.1851V16L23 16.6929V18.3368H25.2183V24.1851H23V26H29.8565V24.1851H27.6382Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'h2',
      name: 'Заголовок 2',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20.991 22.9054V24.8629C20.9838 25.5898 20.5952 25.9928 19.8683 25.9928C17.9467 26 16.0252 26 14.1108 25.9928C13.4055 25.9928 13.0097 25.597 13.0025 24.8845C12.9953 24.2224 12.9953 23.5603 13.0025 22.8982C13.0097 22.2792 13.2976 21.9986 13.9237 21.9914H14.9816V18.0115H7.02195V21.9986H8.07989C8.70601 22.0058 8.99388 22.2864 9.00108 22.9054C9.00828 23.5675 9.00828 24.2296 9.00108 24.8917C8.99388 25.6042 8.60525 26 7.89277 26C5.97121 26 4.04966 26 2.1353 26C1.40842 26 1.01979 25.6042 1.01259 24.8701C1.0054 24.2152 1.0054 23.5603 1.0054 22.9054C1.0054 22.3224 1.32206 21.9986 1.905 21.9914C2.26484 21.9842 2.62469 21.9914 2.97733 21.9914V9.97985C2.5815 9.97985 2.21447 9.98705 1.84743 9.97985C1.32206 9.97265 1.01259 9.65599 1.0054 9.13782C0.998201 8.44692 0.998201 7.75603 1.0054 7.06513C1.01259 6.39583 1.41562 6 2.09212 6H8.08708C8.69162 6 8.98669 6.28787 8.99388 6.89241C9.00108 7.62648 9.00108 8.35336 8.99388 9.08744C8.98669 9.69198 8.69881 9.98705 8.09428 9.98705C7.73444 9.98705 7.38179 9.98705 7.02195 9.98705V13.9813H14.9673V9.98705C14.6074 9.98705 14.2548 9.98705 13.8949 9.98705C13.2904 9.97985 13.0025 9.69198 12.9953 9.08744C12.9881 8.35336 12.9881 7.62648 12.9953 6.89241C13.0025 6.28787 13.2976 6 13.9021 6H19.8971C20.5736 6 20.9766 6.39583 20.9838 7.06513C20.991 7.75603 20.991 8.44692 20.9838 9.13782C20.9766 9.66319 20.6671 9.97265 20.1418 9.97985C19.7747 9.98705 19.4005 9.97985 19.0119 9.97985V21.9914H20.0842C20.6671 22.0058 20.9838 22.3224 20.991 22.9054Z'
            fill='#59647A'
          />
          <path
            d='M26.2423 24.3797L26.2216 24.3004L27.566 22.8415C28.4396 21.8908 29.0386 21.1645 29.3641 20.6823C29.6891 20.2001 29.8518 19.6403 29.8518 19.0126C29.8518 18.0802 29.5458 17.3431 28.9342 16.806C28.3224 16.2696 27.4705 16 26.3788 16C25.3508 16 24.5251 16.319 23.9018 16.9579C23.2786 17.5969 22.9783 18.3661 23.0012 19.285L23.015 19.3162H25.2666V19.3164C25.2666 18.9113 25.3656 18.4968 25.5635 18.2035C25.7614 17.91 26.0332 17.7682 26.3789 17.7682C26.7655 17.7682 27.0556 17.8842 27.249 18.1115C27.4422 18.3391 27.539 18.6452 27.539 19.0273C27.539 19.2957 27.4434 19.6125 27.2524 19.9762C27.0614 20.3401 26.7262 20.7543 26.3211 21.2183L23.0906 24.576V26H29.9766V24.3797H26.2423Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'h3',
      name: 'Заголовок 3',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20.991 22.9054V24.8629C20.9838 25.5898 20.5952 25.9928 19.8683 25.9928C17.9467 26 16.0252 26 14.1108 25.9928C13.4055 25.9928 13.0097 25.597 13.0025 24.8845C12.9953 24.2224 12.9953 23.5603 13.0025 22.8982C13.0097 22.2792 13.2976 21.9986 13.9237 21.9914H14.9816V18.0115H7.02195V21.9986H8.07989C8.70601 22.0058 8.99388 22.2864 9.00108 22.9054C9.00828 23.5675 9.00828 24.2296 9.00108 24.8917C8.99388 25.6042 8.60525 26 7.89277 26C5.97121 26 4.04966 26 2.1353 26C1.40842 26 1.01979 25.6042 1.01259 24.8701C1.0054 24.2152 1.0054 23.5603 1.0054 22.9054C1.0054 22.3224 1.32206 21.9986 1.905 21.9914C2.26484 21.9842 2.62469 21.9914 2.97733 21.9914V9.97985C2.5815 9.97985 2.21447 9.98705 1.84743 9.97985C1.32206 9.97265 1.01259 9.65599 1.0054 9.13782C0.998201 8.44692 0.998201 7.75603 1.0054 7.06513C1.01259 6.39583 1.41562 6 2.09212 6H8.08708C8.69162 6 8.98669 6.28787 8.99388 6.89241C9.00108 7.62648 9.00108 8.35336 8.99388 9.08744C8.98669 9.69198 8.69881 9.98705 8.09428 9.98705C7.73444 9.98705 7.38179 9.98705 7.02195 9.98705V13.9813H14.9673V9.98705C14.6074 9.98705 14.2548 9.98705 13.8949 9.98705C13.2904 9.97985 13.0025 9.69198 12.9953 9.08744C12.9881 8.35336 12.9881 7.62648 12.9953 6.89241C13.0025 6.28787 13.2976 6 13.9021 6H19.8971C20.5736 6 20.9766 6.39583 20.9838 7.06513C20.991 7.75603 20.991 8.44692 20.9838 9.13782C20.9766 9.66319 20.6671 9.97265 20.1418 9.97985C19.7747 9.98705 19.4005 9.97985 19.0119 9.97985V21.9914H20.0842C20.6671 22.0058 20.9838 22.3224 20.991 22.9054Z'
            fill='#59647A'
          />
          <path
            d='M29.5839 21.7428C29.2897 21.3526 28.8821 21.0632 28.3608 20.8716C28.8196 20.6621 29.1862 20.3715 29.4601 19.9996C29.7342 19.6276 29.8711 19.2208 29.8711 18.7801C29.8711 17.8934 29.5547 17.2084 28.9222 16.725C28.2896 16.2414 27.4453 16 26.3893 16C25.4803 16 24.7074 16.2406 24.0702 16.7219C23.433 17.2031 23.1277 17.8658 23.1546 18.641L23.1681 18.7158H25.3602C25.3602 18.3191 25.4713 18.1955 25.6942 18.0261C25.917 17.8567 26.1755 17.755 26.4695 17.755C26.8438 17.755 27.1288 17.8509 27.325 18.0604C27.521 18.2699 27.619 18.5238 27.619 18.8313C27.619 19.2189 27.5099 19.5403 27.2917 19.7587C27.0733 19.9771 26.7615 20.1044 26.356 20.1044H25.2973V21.6913H26.356C26.8061 21.6913 27.1547 21.8242 27.402 22.0402C27.6494 22.2564 27.773 22.6131 27.773 23.0852C27.773 23.424 27.6548 23.7109 27.4189 23.9336C27.1827 24.1564 26.8663 24.2706 26.4697 24.2706C26.1222 24.2706 25.8272 24.1209 25.5842 23.9094C25.3412 23.6979 25.22 23.4766 25.22 23.0798H23.0143L23.0011 23.1655C22.9788 24.0744 23.3095 24.7913 23.9934 25.2702C24.6774 25.749 25.476 26 26.3893 26C27.4498 26 28.3208 25.7508 29.0025 25.2406C29.6843 24.7306 30.0249 24.0326 30.0249 23.1415C30.0249 22.5979 29.8781 22.1328 29.5839 21.7428Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'importantThought',
      name: 'Важная мысль',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M27.7931 24.5862H4V28H27.7931V24.5862Z' fill='#59647A' />
          <path d='M27.7931 4H4V7.41381H27.7931V4Z' fill='#59647A' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4 10.9311H28.5V21.1724H4V10.9311ZM7.5 14.3449V17.7586H25V14.3449H7.5Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'code',
      name: 'Код',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_2614_2299)'>
            <path
              d='M31.8016 15.5195L23.6347 7.35258C23.3807 7.09862 22.9314 7.09862 22.6774 7.35258L20.9068 9.12317C20.6424 9.38762 20.6424 9.8163 20.9068 10.0808L26.8247 15.998L20.9072 21.9162C20.6427 22.1806 20.6427 22.6093 20.9072 22.8738L22.6778 24.6444C22.8047 24.7714 22.9771 24.8431 23.1566 24.8431C23.3357 24.8431 23.5084 24.7714 23.6354 24.6444L31.8016 16.4778C32.0661 16.213 32.0661 15.784 31.8016 15.5195Z'
              fill='#59647A'
            />
            <path
              d='M11.0925 21.9162L5.17566 15.9986L11.0936 10.0814C11.2205 9.95441 11.292 9.7824 11.292 9.6026C11.292 9.42313 11.2209 9.25078 11.0936 9.1238L9.32296 7.35321C9.19598 7.22623 9.02363 7.15479 8.84416 7.15479C8.6647 7.15479 8.49235 7.22623 8.36537 7.35321L0.198097 15.5195C-0.0663578 15.7839 -0.0663578 16.2126 0.198097 16.4774L8.36503 24.644C8.49201 24.771 8.66436 24.8428 8.84383 24.8428C9.02329 24.8428 9.19564 24.771 9.32262 24.644L11.0932 22.8734C11.3577 22.6093 11.3577 22.1806 11.0925 21.9162Z'
              fill='#59647A'
            />
            <path
              d='M20.644 4.60507C20.558 4.44728 20.4131 4.33012 20.2411 4.27933L18.8057 3.85573C18.4475 3.7494 18.0703 3.95494 17.9646 4.31353L11.3011 26.8795C11.2503 27.0519 11.27 27.2371 11.3556 27.3945C11.4413 27.5527 11.5866 27.6695 11.7586 27.7206L13.1939 28.1442C13.2579 28.1632 13.3226 28.1723 13.3859 28.1723C13.6782 28.1723 13.948 27.9814 14.0351 27.6864L20.6986 5.1201C20.7494 4.94774 20.7301 4.76253 20.644 4.60507Z'
              fill='#59647A'
            />
          </g>
          <defs>
            <clipPath id='clip0_2614_2299'>
              <rect width='32' height='32' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  ],
  lessonStructure: [
    {
      id: 'lessonSeparator',
      name: 'Разделитель урока',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10.9999 7.25L14.5 7.25L14.5 12.5L18 12.5L18 7.25L21.5 7.25L16.25 2L10.9999 7.25Z'
            fill='#59647A'
          />
          <path
            d='M21.5 24.75L18 24.75L18 19.5L14.5 19.5L14.5 24.75L10.9999 24.75L16.2499 30L21.5 24.75Z'
            fill='#59647A'
          />
          <path d='M4 14.25H28.5V17.75H4V14.25Z' fill='#59647A' />
        </svg>
      ),
    },
  ],
  media: [
    {
      id: 'photo',
      name: 'Фотография',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M15.9693 9.77783L14.0249 14.0556L9.74707 16L14.0249 17.9445L15.9693 22.2222L17.9137 17.9445L22.1915 16L17.9137 14.0556L15.9693 9.77783Z'
            fill='#59647A'
          />
          <path
            d='M22.2845 9.68448L23.7467 12.8889L25.2089 9.68448L28.4134 8.22221L25.2089 6.76001L23.7467 3.55554L22.2845 6.76001L19.0801 8.22221L22.2845 9.68448Z'
            fill='#59647A'
          />
          <path
            d='M26.8889 12.8889V26.8889H5.08V5.11114H19.08V2H5.11114C3.4 2 2 3.4 2 5.11114V26.8889C2 28.6 3.4 30 5.11114 30H26.8889C28.6 30 30 28.6 30 26.8889V12.8889H26.8889Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'video',
      name: 'Видео',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_2614_114)'>
            <path
              d='M31.9916 15.4778C31.7231 7.07162 24.9281 0.276843 16.5222 0.00839499C11.9482 -0.136724 7.669 1.60426 4.50648 4.86945C1.46616 8.00838 -0.13128 12.1465 0.00844845 16.5221C0.276672 24.928 7.07168 31.7231 15.4776 31.9913C15.6526 31.9969 15.826 31.9996 15.9999 31.9996C20.3701 31.9996 24.4519 30.2703 27.4933 27.1302C30.5336 23.9915 32.1313 19.8531 31.9916 15.4778ZM25.073 24.7861C22.672 27.265 19.4497 28.6302 15.9999 28.6302C15.862 28.6302 15.724 28.6279 15.585 28.6236C8.95082 28.4118 3.58814 23.0491 3.37607 16.4147C3.26577 12.9593 4.52669 9.69163 6.92678 7.21382C9.32777 4.73488 12.55 3.36973 15.9999 3.36973C16.1378 3.36973 16.2758 3.37198 16.4148 3.37624C23.049 3.58808 28.4116 8.95076 28.6237 15.5851C28.734 19.0406 27.4729 22.3083 25.073 24.7861Z'
              fill='#59647A'
            />
            <path
              d='M21.2483 15.0702L14.1532 9.98298C13.3963 9.44024 12.3428 9.98118 12.3428 10.9126V21.0873C12.3428 22.0187 13.3963 22.5596 14.1532 22.0169L21.2481 16.9294C21.8847 16.4731 21.8847 15.5267 21.2483 15.0702Z'
              fill='#59647A'
            />
          </g>
          <defs>
            <clipPath id='clip0_2614_114'>
              <rect width='32' height='32' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  ],
  tasks: [
    {
      id: 'singleChoice',
      name: 'С единичным выбором',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M31.0736 22.0833H9.73828V26.3503H31.0736V22.0833Z'
            fill='#59647A'
          />
          <path
            d='M31.0736 13.6785H9.73828V17.9455H31.0736V13.6785Z'
            fill='#59647A'
          />
          <path
            d='M31.0736 5.01562H9.73828V9.28267H31.0736V5.01562Z'
            fill='#59647A'
          />
          <path
            d='M4 9.69995C2.3431 9.69995 1 8.35685 1 6.69995C1 5.04305 2.3431 3.69995 4 3.69995C5.6569 3.69995 7 5.04305 7 6.69995C7 8.35685 5.6569 9.69995 4 9.69995Z'
            fill='#59647A'
          />
          <path
            d='M4 18.6C2.3431 18.6 1 17.2569 1 15.6C1 13.9431 2.3431 12.6 4 12.6C5.6569 12.6 7 13.9431 7 15.6C7 17.2569 5.6569 18.6 4 18.6ZM4 16.8C4.31827 16.8 4.62349 16.6736 4.84852 16.4485C5.07358 16.2235 5.2 15.9182 5.2 15.6C5.2 15.2817 5.07358 14.9765 4.84852 14.7514C4.62349 14.5264 4.31827 14.4 4 14.4C3.68173 14.4 3.37652 14.5264 3.15147 14.7514C2.92643 14.9765 2.8 15.2817 2.8 15.6C2.8 15.9182 2.92643 16.2235 3.15147 16.4485C3.37652 16.6736 3.68173 16.8 4 16.8Z'
            fill='#59647A'
          />
          <path
            d='M4 27.5C2.3431 27.5 1 26.1569 1 24.5C1 22.8431 2.3431 21.5 4 21.5C5.6569 21.5 7 22.8431 7 24.5C7 26.1569 5.6569 27.5 4 27.5ZM4 25.7C4.31827 25.7 4.62349 25.5736 4.84852 25.3485C5.07358 25.1235 5.2 24.8183 5.2 24.5C5.2 24.1817 5.07358 23.8765 4.84852 23.6515C4.62349 23.4264 4.31827 23.3 4 23.3C3.68173 23.3 3.37652 23.4264 3.15147 23.6515C2.92643 23.8765 2.8 24.1817 2.8 24.5C2.8 24.8183 2.92643 25.1235 3.15147 25.3485C3.37652 25.5736 3.68173 25.7 4 25.7Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'multipleChoice',
      name: 'Со множественным выбором',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M31.0736 22.0833H9.73828V26.3503H31.0736V22.0833Z'
            fill='#59647A'
          />
          <path
            d='M31.0736 13.6785H9.73828V17.9455H31.0736V13.6785Z'
            fill='#59647A'
          />
          <path
            d='M31.0736 5.01562H9.73828V9.28267H31.0736V5.01562Z'
            fill='#59647A'
          />
          <path
            d='M4 9.69995C2.3431 9.69995 1 8.35685 1 6.69995C1 5.04305 2.3431 3.69995 4 3.69995C5.6569 3.69995 7 5.04305 7 6.69995C7 8.35685 5.6569 9.69995 4 9.69995Z'
            fill='#59647A'
          />
          <path
            d='M4 18.6C2.3431 18.6 1 17.2569 1 15.6C1 13.9431 2.3431 12.6 4 12.6C5.6569 12.6 7 13.9431 7 15.6C7 17.2569 5.6569 18.6 4 18.6Z'
            fill='#59647A'
          />
          <path
            d='M4 27.5C2.3431 27.5 1 26.1569 1 24.5C1 22.8431 2.3431 21.5 4 21.5C5.6569 21.5 7 22.8431 7 24.5C7 26.1569 5.6569 27.5 4 27.5ZM4 25.7C4.31827 25.7 4.62349 25.5736 4.84852 25.3485C5.07358 25.1235 5.2 24.8183 5.2 24.5C5.2 24.1817 5.07358 23.8765 4.84852 23.6515C4.62349 23.4264 4.31827 23.3 4 23.3C3.68173 23.3 3.37652 23.4264 3.15147 23.6515C2.92643 23.8765 2.8 24.1817 2.8 24.5C2.8 24.8183 2.92643 25.1235 3.15147 25.3485C3.37652 25.5736 3.68173 25.7 4 25.7Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'comparison',
      name: 'Сопоставление',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.216 19.2H0V22.4H11.216V27.2L17.6 20.8L11.216 14.4V19.2Z'
            fill='#59647A'
          />
          <path
            d='M20.7839 9.59999V4.79999L14.3999 11.2L20.7839 17.6V12.8H31.9999V9.59999H20.7839Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
    {
      id: 'openAnswer',
      name: 'Итоговое с открытым ответом',
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.42242 19.1352H6V18.5088H6.80326V13.6265H6V13H8.42242V13.6265H7.61929V18.5093H8.42235L8.42242 19.1352Z'
            fill='#59647A'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 8H32V24.3764H0V8ZM3.57143 11.4589V20.9176H28.4286V11.4589H3.57143Z'
            fill='#59647A'
          />
        </svg>
      ),
    },
  ],
};
