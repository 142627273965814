import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useMediaQuery } from '@mui/material';

import { CurrentLessonContent } from './CurrentLessonContent';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  unlockNextLesson,
  unlockNextModule,
} from '../../../redux/user/reducer';
import { userSelector } from '../../../redux/user/selectors';
import { Button } from '../../../shared/components/Button';
import { MEDIA_QUERY_TABLET } from '../../../shared/theme/mediaQuery';
import { COURSES_URL_PART } from '../../../shared/constants/paths';
import { api } from '../../../api';
import { Content as ContentType } from '../../../api/lesson-content/lessonContentType';
import { ContentProps } from './types';
import styles from './styles.module.scss';

export const Content = ({ course, moduleId, lessonId }: ContentProps) => {
  // TODO: Выяснить, почему происходит лишний ререндер (IMPORTANT)
  // При сужении до мобильного экрана тоже лишний ререндер
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { courseName } = useParams();

  const [content, setContent] = useState<ContentType>();
  const [isNextLessonAvailaible, setIsNextLessonAvailaible] =
    useState<boolean>();
  const user = useAppSelector(userSelector);

  const matchesTablet = useMediaQuery(MEDIA_QUERY_TABLET);

  useEffect(() => {
    const id = course.modules[moduleId - 1].lessons[lessonId - 1].id;
    const asyncEffect = async (id: number) => {
      try {
        const response = await api.lesson.lessonRetrieve(id);
        const dataPars = JSON.parse(response.data.content);
        setContent(dataPars);
        setIsNextLessonAvailaible(
          user?.groups.includes('student')
            ? response.data.next.has_student_access
            : true
        );
      } catch (e) {
        console.error(e);
      }
    };

    asyncEffect(id);
  }, [moduleId, lessonId, course.modules, user?.groups]);

  const isFirstLessonInCourse = lessonId === 1 && moduleId === 1;
  const isLastLessonInCourse =
    moduleId === course.modules.length &&
    lessonId === course.modules[course.modules.length - 1].lessons.length;

  const handleNavigateButton = (previous: boolean) => {
    const path = `/${COURSES_URL_PART}/${courseName}/module`;
    const isFirstLessonInModule = lessonId === 1;
    const isLastLessonInModule =
      lessonId === course.modules[moduleId - 1].lessons.length;

    if (previous) {
      const lastLessonNumberInPreviousModule =
        course.modules[moduleId - 2]?.lessons.length;

      navigate(
        `${path}-${moduleId + (isFirstLessonInModule ? -1 : 0)}/lesson-${
          isFirstLessonInModule
            ? lastLessonNumberInPreviousModule
            : lessonId - 1
        }`
      );
      return;
    }

    navigate(
      `${path}-${moduleId + (isLastLessonInModule ? 1 : 0)}/lesson-${
        isLastLessonInModule ? 1 : lessonId + 1
      }`
    );

    if (
      isLastLessonInModule &&
      moduleId === user?.active_course.module.position
    ) {
      dispatch(unlockNextModule());
      return;
    }

    dispatch(unlockNextLesson());
  };

  return content ? (
    <div className={styles.wrapper}>
      <p
        className={styles['lesson-info']}
      >{`Модуль ${moduleId}, урок ${lessonId}`}</p>
      <h1 className={styles.header}>
        {course.modules[moduleId - 1].lessons[lessonId - 1].name}
      </h1>
      <CurrentLessonContent content={content} matchesTablet={matchesTablet} />
      <div
        className={cn(styles.buttons, {
          [styles['buttons-centered']]:
            isFirstLessonInCourse || isLastLessonInCourse,
          [styles['buttons-mobile']]: !matchesTablet,
        })}
      >
        {!isFirstLessonInCourse && (
          <Button
            type='secondary'
            onClick={() => handleNavigateButton(true)}
            withTheme
            className={styles.back}
          >
            Предыдущий урок
          </Button>
        )}
        {!isLastLessonInCourse && (
          <Button
            onClick={() => handleNavigateButton(false)}
            withTheme
            className={styles.next}
            disabled={!isNextLessonAvailaible}
          >
            Следующий урок
          </Button>
        )}
      </div>
    </div>
  ) : null;
};
