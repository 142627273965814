import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';

import PanelContent from './PanelContent';
import PanelNewBlock from './PanelNewBlock';
import { IDataCourse } from '../../Modules/Modules';
import { useFormatMarkdownInSlate } from '../hooks';
import { TDataChange } from './types';
import { Content as TContent } from '../../../api/lesson-content/lessonContentType';
import styles from './LessonsChangeContent.module.scss';

interface IProps {
  dataCourse: IDataCourse;
  dataContent: TContent;
  dataChange: TDataChange;
  setDataChange: React.Dispatch<React.SetStateAction<TDataChange>>;
}

const LessonsChangeContent = ({
  dataCourse,
  dataContent,
  dataChange,
  setDataChange,
}: IProps) => {
  const ref = useRef(null);
  const search = useLocation().search;
  const idModule = new URLSearchParams(search).get('module-id');
  const idLesson = new URLSearchParams(search).get('lesson-id');

  const { formatRes } = useFormatMarkdownInSlate();

  const [isOpenPanelNewBlock, toggleOpenPanelNewBlock] = useToggle(false);

  const [title, setTitle] = useState('');
  const [newBlockId, setNewBlockId] = useState('');

  const arrayDataChange = useMemo(
    () => Object.values(dataChange),
    [dataChange]
  );

  const lengthDataChange = useMemo(
    () => arrayDataChange.length,
    [arrayDataChange]
  );

  const module = useMemo(
    () => dataCourse.modules.find(item => item.id === +idModule),
    [dataCourse, idModule]
  );

  const lesson = useMemo(
    () => module.lessons.find(item => item.id === +idLesson),
    [idLesson, module]
  );

  const courseTags = useMemo(() => dataCourse.tags[0], [dataCourse]);

  useEffect(() => {
    setTitle(lesson?.name);
  }, [lesson]);

  useEffect(() => {
    if (dataContent) {
      const dataContentObj = dataContent.reduce((obj, item, index) => {
        const { type } = item;

        if (type === 'text') {
          //const id = Math.random();
          const position = index + 1;
          const textFormat = formatRes(`${item.text}`);

          const objFormat = textFormat.reduce((obj, item) => {
            const { type, children } = item;
            const id = Math.random();

            const newChildren = children?.map(item => {
              const { type } = item;

              if (type === 'link') {
                return { ...item, href: item.link };
              }
              return item;
            });

            if (type === 'unnumberedList' || type === 'numberedList') {
              return {
                ...obj,
                [id]: {
                  id,
                  position,
                  type: type,
                  value: children, //newChildren
                },
              };

              //временное решение от сюда
            } else if (type === 'photo') {
              return {
                ...obj,
                [id]: { id, type, position, value: item.source },
              };
            }
            //до сюда

            return {
              ...obj,
              [id]: {
                id,
                position,
                type: type,
                value: [
                  {
                    type,
                    children: newChildren,
                  },
                ],
              },
            };
          }, {});

          return {
            ...obj,
            ...objFormat,
          };
        } else if (type === 'video' /* || type === "photo "*/) {
          const id = Math.random();
          return {
            ...obj,
            [id]: {
              id,
              type: type,
              position: 0,
              value: item.source,
            },
          };
        } else if (type === 'project_task') {
          const id = Math.random();

          return {
            ...obj,
            [id]: {
              id,
              type: 'openAnswer',
              position: 0,
              header: item.header,
              value: item.content[0], //приходит массив, но в нем всегда приходит одно значение, чтобы не было ошибки берем первое
              isTaskRequired: item.isDoneRequired,
            },
          };
        } else {
          return {
            ...obj,
            item,
          };
        }
      }, {});

      setDataChange(dataContentObj);
    }
  }, [dataContent, formatRes, setDataChange]);

  useEffect(() => {
    if (newBlockId) {
      const id = Math.random();
      const position = lengthDataChange + 1;

      if (newBlockId === 'openAnswer') {
        setDataChange(state => ({
          ...state,
          [id]: {
            id,
            position,
            type: newBlockId,
            header: '',
            value: '',
            isTaskRequired: true,
          },
        }));
      } else {
        setDataChange(state => ({
          ...state,
          [id]: { id, position, type: newBlockId, value: '' },
        }));
      }

      setNewBlockId('');
      toggleOpenPanelNewBlock(false);
    }
  }, [
    newBlockId,
    dataChange,
    toggleOpenPanelNewBlock,
    lengthDataChange,
    setDataChange,
  ]);

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setTitle(value);
  };

  return (
    <div className={styles.content}>
      <div className={styles.desc}>
        Модуль {module?.position}, урок {lesson?.position}
      </div>
      <input
        className={styles.inputTitle}
        placeholder='Название урока'
        value={title}
        onChange={handleChangeTitle}
      />
      <div ref={ref}>
        {lengthDataChange
          ? arrayDataChange.map(
              ({ id, position, type, value, header, isTaskRequired }) => (
                <PanelContent
                  key={id}
                  id={id}
                  position={position}
                  type={type}
                  value={value}
                  header={header}
                  setData={setDataChange}
                  courseTag={courseTags}
                  isTaskRequired={isTaskRequired}
                />
              )
            )
          : null}
      </div>

      <PanelNewBlock
        isOpen={isOpenPanelNewBlock}
        refContent={ref}
        toggleOpen={toggleOpenPanelNewBlock}
        setNewBlockId={setNewBlockId}
      />
    </div>
  );
};

export default LessonsChangeContent;