import React from 'react';

import Editor from './Editor';
import { Photo, Video } from './Media';
import { TDataChange } from '../types';
import { OpenAnswer } from './Tasks';
import { ImportantThought } from './ImportantThought';

type TProps = {
  id: number;
  position: number;
  value: string;
  header?: string;
  type: string;
  courseTag: string;
  setData: React.Dispatch<React.SetStateAction<TDataChange>>;
  isTaskRequired?: boolean;
};

const PanelContent = ({
  id,
  position,
  type,
  value,
  header,
  setData,
  courseTag,
  isTaskRequired,
}: TProps) => {
  return (
    <>
      {type === 'paragaph' && (
        <Editor initialValue={value} id={id} setData={setData} />
      )}
      {type === 'unnumberedList' && (
        <Editor
          type='list-item'
          initialValue={value}
          id={id}
          setData={setData}
        />
      )}
      {type === 'numberedList' && (
        <Editor
          type='orderedList'
          initialValue={value}
          id={id}
          setData={setData}
        />
      )}
      {type === 'h1' && (
        <Editor
          type='headingOne'
          initialValue={value}
          id={id}
          setData={setData}
        />
      )}
      {type === 'h2' && (
        <Editor
          type='headingTwo'
          initialValue={value}
          id={id}
          setData={setData}
        />
      )}
      {type === 'h3' && (
        <Editor
          type='headingThree'
          initialValue={value}
          id={id}
          setData={setData}
        />
      )}
      {type === 'photo' && <Photo id={id} data={value} setData={setData} />}
      {type === 'video' && <Video id={id} data={value} setData={setData} />}
      {type === 'openAnswer' && header !== undefined && (
        <OpenAnswer
          id={id}
          data={value}
          header={header}
          setData={setData}
          isRequired={isTaskRequired}
        />
      )}
      {type === 'importantThought' && (
        <ImportantThought
          id={id}
          header={header}
          data={value}
          setData={setData}
          courseTag={courseTag}
        />
      )}
    </>
  );
};

export default PanelContent;
