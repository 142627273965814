import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { DeleteButton } from './DeleteButton';
import { TDataChange } from '../../types';
import {
  COLOR_BLUE,
  COLOR_CHARTREUSE,
  COLOR_ROSE,
  COLOR_VIOLET,
  COLOR_YELLOW,
  COLOR_GREY1,
} from '../../../../../shared/theme/colors';
import styles from './ImportantThought.module.scss';

const MIN_TITLE_HEIGHT = 30;
const MIN_DESCRIPTION_HEIGHT = 28;

type TProps = {
  id: number;
  header: string;
  data: string;
  setData: React.Dispatch<React.SetStateAction<TDataChange>>;
  courseTag: string;
};

const getColorByCourseTag = (courseTag: string) => {
  switch (courseTag) {
    case 'design':
      return COLOR_BLUE;
    case 'office':
      return COLOR_CHARTREUSE;
    case 'programming':
      return COLOR_ROSE;
    case 'analytics':
      return COLOR_VIOLET;
    case 'softskills':
      return COLOR_YELLOW;
    default:
      return COLOR_GREY1;
  }
};

export const ImportantThought = ({
  id,
  header,
  data,
  setData,
  courseTag,
}: TProps) => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const [title, setTitle] = useState(header);
  const [description, setDescription] = useState(data);

  const borderColor = getColorByCourseTag(courseTag);

  useEffect(() => {
    setData(state => ({
      ...state,
      [id]: { ...state[id], header: title, value: description },
    }));
  }, [id, title, description, setData]);

  useLayoutEffect(() => {
    titleRef.current.style.height = 'inherit';
    titleRef.current.style.height = `${Math.max(
      titleRef.current.scrollHeight,
      MIN_TITLE_HEIGHT
    )}px`;
  }, [title]);

  useLayoutEffect(() => {
    descriptionRef.current.style.height = 'inherit';
    descriptionRef.current.style.height = `${Math.max(
      descriptionRef.current.scrollHeight,
      MIN_DESCRIPTION_HEIGHT
    )}px`;
  }, [description]);

  const handleChangeTitle: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = e => {
    const { value } = e.currentTarget;
    setTitle(value);
  };

  const handleChangeDescription: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = e => {
    const { value } = e.currentTarget;
    setDescription(value);
  };

  return (
    <div
      className={styles.block}
      style={{ border: `4px solid ${borderColor}` }}
    >
      <DeleteButton id={id} setData={setData} />
      <textarea
        className={styles.title}
        placeholder='ВВЕДИТЕ ЗАГОЛОВОК'
        rows={1}
        style={{
          minHeight: MIN_TITLE_HEIGHT,
        }}
        ref={titleRef}
        value={title}
        onChange={handleChangeTitle}
      />
      <textarea
        className={styles.description}
        placeholder='Введите текст'
        rows={1}
        style={{
          minHeight: MIN_DESCRIPTION_HEIGHT,
        }}
        ref={descriptionRef}
        value={description}
        onChange={handleChangeDescription}
      />
    </div>
  );
};
