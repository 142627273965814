import { useCallback } from 'react';

import { useFormatSlateInMarkdown } from './';
import { TDataChange } from '../LessonsChangeContent/types';

type TParams = {
  data: TDataChange;
};

export const useFormatDataForSave = () => {
  const { formatRes: formatResSlate } = useFormatSlateInMarkdown();

  const formatDataSave = useCallback(
    ({ data }: TParams) => {
      const dataArr = Object.values(data);

      const dataFormat = dataArr?.map(item => {
        const { type, value, isTaskRequired, photoLabel } = item;

        let valueFormat: any = value;

        if (Array.isArray(value)) {
          valueFormat = value.map(item => ({ ...item, type }));
        }

        if (
          type === 'paragaph' ||
          type === 'unnumberedList' ||
          type === 'numberedList' ||
          type === 'h1' ||
          type === 'h2' ||
          type === 'h3'
        ) {
          //@ts-ignore
          const newValue = valueFormat.map(item => {
            const { type, children } = item;

            const newChildren = children.map(item => {
              const { type } = item;

              if (type === 'link') {
                return { ...item, link: item.href };
              }
              return item;
            });

            return { type, children: newChildren };
          });

          return {
            type: 'text',
            text: formatResSlate(newValue, type) /* .replace(/[\n\r]+/g, "") */,
            isImportant: false,
          };
        } else if (type === 'video') {
          return {
            type: 'video',
            source: value,
          };
        } else if (type === 'photo') {
          //временное решение
          return {
            type: 'text',
            text: `![${photoLabel}](${value})` /* .replace(/[\n\r]+/g, "") */,
            isImportant: false,
          };
        } else if (type === 'openAnswer') {
          return {
            type: 'project_task',
            id: null,
            header: item?.header,
            content: [value] /* временное решение */,
            isDoneRequired: isTaskRequired,
            isAcceptRequired: true,
          };
        }

        //тут будет возвращаться другие типы (задания)
        return null;
      });

      return dataFormat;
    },
    [formatResSlate]
  );

  return { formatDataSave };
};