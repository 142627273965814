import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import cn from 'classnames';
import { CurrentLessonCard } from './CurrentLessonCard';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUserThunk } from '../../redux/user/thunks';
import { userGroupSelector, userSelector } from '../../redux/user/selectors';
import { getCourseThunk } from '../../redux/course/thunks';
import { getUserCoursesThunk } from '../../redux/userCourses/thunks';
import { userCoursesSelector } from '../../redux/userCourses/selectors';
import {
  activeModuleLengthSelector,
  courseModulesSelector,
  courseSelector,
  courseTagSelector,
} from '../../redux/course/selectors';
import {
  MEDIA_QUERY_DESKTOP,
  MEDIA_QUERY_MOBILE,
  MEDIA_QUERY_TABLET,
} from '../../shared/theme/mediaQuery';
import { StyledLinearProgress } from '../../shared/components/mui-styled/LinearProgress';
import { COURSES_URL_PART } from '../../shared/constants/paths';
import { setTheme } from '../../shared/utils/setTheme';
import { MentorPageContent } from './MentorPageContent';
import { ModuleCard } from './ModuleCard';
import { MAX_PROGRESS_LINE_VALUE, MIN_PROGRESS_LINE_VALUE } from './constants';
import { Navigation } from '../../shared/components/Navigation';
import styles from './styles.module.scss';

export const MainPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const matchesDesktop = useMediaQuery(MEDIA_QUERY_DESKTOP);
  const matchesTablet = useMediaQuery(MEDIA_QUERY_TABLET);
  const matchesMobile = useMediaQuery(MEDIA_QUERY_MOBILE);

  const courses = useAppSelector(userCoursesSelector);
  const courseTag = useAppSelector(courseTagSelector);
  const activeCourseModules = useAppSelector(courseModulesSelector);
  const user = useAppSelector(userSelector);
  const userGroup = useAppSelector(userGroupSelector);
  const course = useAppSelector(courseSelector);
  const activeModuleLength = useAppSelector(state =>
    activeModuleLengthSelector(state, user?.active_course?.module.id)
  );

  useEffect(() => {
    if (!courses && userGroup) {
      dispatch(getUserCoursesThunk(userGroup));
    }
    if (user?.active_course) {
      dispatch(getCourseThunk(user?.active_course.course.id, userGroup));
    }
  }, [courses, user?.active_course, userGroup, dispatch]);

  useEffect(() => {
    if (!user) {
      dispatch(getUserThunk());
    }
  }, [dispatch, user]);

  const handleModuleCardClick = (moduleNumber: number, courseName?: string) => {
    if (
      courseName &&
      user?.active_course &&
      moduleNumber > user.active_course.module.position
    ) {
      return;
    }
    navigate(
      `${COURSES_URL_PART}/${courseName}/module-${moduleNumber}/lesson-1`
    );
  };

  const isModuleLocked = (
    currentModulePosition: number,
    activeCourseModulePosition: number
  ) => {
    if (currentModulePosition === activeCourseModulePosition) {
      return null;
    }

    return currentModulePosition > activeCourseModulePosition ? true : false;
  };

  const PAGE_CONTAINER_STYLES = cn(
    { [styles['page-container']]: matchesDesktop },
    {
      [styles['page-container-tablet']]: matchesTablet && !matchesDesktop,
    },
    {
      [styles['page-container-mobile']]: matchesMobile && !matchesTablet,
    }
  );

  return (
    <ThemeProvider theme={setTheme(courseTag)}>
      <div>
        <Navigation />
        {user?.active_course && (
          <>
            {userGroup === 'student' && (
              <div className={PAGE_CONTAINER_STYLES}>
                <p className={'size-s'}>Вы проходите курс</p>
                <h1 className={styles['course-header']}>
                  {user?.active_course.course.name}
                </h1>
                <div className={styles['progress-container']}>
                  {activeCourseModules?.map((module, index) => {
                    let value = MAX_PROGRESS_LINE_VALUE;

                    if (
                      user.active_course &&
                      index > user.active_course.module.position - 1
                    ) {
                      value = MIN_PROGRESS_LINE_VALUE;
                    }

                    if (
                      user.active_course &&
                      index === user.active_course.module.position - 1
                    ) {
                      value =
                        (value / module.lessons.length) *
                        user.active_course.lesson.position;
                    }

                    if (value > MAX_PROGRESS_LINE_VALUE) {
                      value = MAX_PROGRESS_LINE_VALUE;
                    }

                    return (
                      <StyledLinearProgress
                        key={index}
                        value={value}
                        variant='determinate'
                        className={styles.progress}
                      />
                    );
                  })}
                </div>
                <p
                  className={cn(styles['progress-description'], 'size-s')}
                >{`Модуль ${user?.active_course.module.position} из ${activeCourseModules?.length} «${user?.active_course.module.name}»`}</p>
                <h1 className={styles.header}>Ваш урок</h1>
                <CurrentLessonCard
                  module={user.active_course.module.position}
                  lesson={user.active_course.lesson.position}
                  title={user.active_course.module.name}
                  courseName={user.active_course.course.name}
                  className={cn(styles['current-lesson'], {
                    [styles['current-lesson-mobile']]: !matchesTablet,
                  })}
                >
                  <p className={cn(styles['current-lesson-text'], 'size-s')}>
                    {user.active_course.module.description}
                  </p>
                </CurrentLessonCard>
                <h1 className={styles.header}>Все модули курса</h1>
                <div className={styles['modules-container']}>
                  {activeCourseModules?.map(
                    (module, index) =>
                      user?.active_course && (
                        <ModuleCard
                          key={module.id}
                          number={index + 1}
                          tag={user?.active_course.course.tags[0]}
                          title={module.name}
                          isLocked={isModuleLocked(
                            index + 1,
                            user.active_course.module.position
                          )}
                          progress={
                            activeModuleLength &&
                            index + 1 === user.active_course.module.position
                              ? {
                                  total: activeModuleLength,
                                  done: user.active_course.lesson.position,
                                }
                              : undefined
                          }
                          className={cn({
                            [styles['module-card-mobile']]:
                              matchesMobile && !matchesTablet,
                          })}
                          onClick={() =>
                            handleModuleCardClick(
                              index + 1,
                              user.active_course?.course.name
                            )
                          }
                        >
                          {module?.description}
                        </ModuleCard>
                      )
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {userGroup === 'mentor' && (
          <MentorPageContent className={PAGE_CONTAINER_STYLES} />
        )}
      </div>
    </ThemeProvider>
  );
};
